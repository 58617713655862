import config from '@/_config';

export default {
  company_level: [0, 30],
  work_days: [5],
  billings: null,
  daily_rate: [1000, 7000],
  budget: [0, 500000],
  end_months: [6],
  attachments: [],
  nda_attachment: [],
  category_ids: [],
  sub_categories: [],
  skills: [],
  optional_skills: [],
  salary_confidentiality: true,
  contract_type: 'missions',
  salary: [config.company.salary.min, config.company.salary.max],
};
