/*
/!\ PLEASE DO NOT USE THE DEFAULT EXPORT OF THIS FILE TO GET COMPANY TYPES
    INSTEAD USE HELPER getCompanyTypes() from @/helper/company.js
*/

import { FREELANCE } from '@/constants/companyKind';
import { CLIENT, EXPERT } from '@/constants/userKind';

export const startup = {
  label: 'fields.companyKinds.startup',
  value: 'startup',
  id: 'startup',
  other: false,
  selectable: true,
  solo: false,
};

export const esn = {
  label: 'fields.companyKinds.esn',
  value: 'esn',
  id: 'esn',
  other: false,
  selectable: true,
  solo: false,
};

export const agency = {
  label: 'fields.companyKinds.agency',
  value: 'agency',
  id: 'agency',
  other: true,
  selectable: true,
  solo: false,
};

export const freelance = {
  label: 'fields.companyKinds.freelance',
  value: FREELANCE,
  id: FREELANCE,
  other: false,
  selectable: true,
  solo: true,
};

export const consulting = {
  label: 'fields.companyKinds.consulting',
  value: 'consulting',
  id: 'consulting',
  other: false,
  selectable: true,
  solo: false,
};

export const majorCompany = {
  label: 'fields.companyKinds.major_company',
  value: 'major_company',
  id: 'major_company',
  other: false,
  selectable: true,
  solo: false,
};

export const smes = {
  label: 'fields.companyKinds.smes',
  value: 'smes',
  id: 'smes',
  other: false,
  selectable: true,
  solo: false,
};

export const unknown = {
  label: 'fields.companyKinds.unknown',
  value: 'unknown',
  id: 'unknown',
  selectable: false,
  solo: true,
};

const companyKinds = {
  [EXPERT]: {
    freelance,
    esn,
    agency,
    consulting,
    unknown,
  },
  [CLIENT]: {
    startup,
    esn,
    agency,
    consulting,
    smes,
    majorCompany,
    unknown,
  },
};

export default companyKinds;
