export * from './router_base'; // eslint-disable-line

export const LANDING = '/';
export const LANDING_ENGINEER = `${LANDING}/ingenieur`;
export const LANDING_REFERENCES = `${LANDING}/references-liste`;
export const LANDING_CGU = `${LANDING}/conditions-generales-dutilisation`;
export const LANDING_BLOG = `${LANDING}/blog`;
export const LANDING_PRESS = `${LANDING}/presse`;
export const LANDING_CONTACT = `${LANDING}/contact`;
export const LANDING_VISION = `${LANDING}/vision`;
export const LANDING_SECTORS = `${LANDING}/secteurs`;
export const LANDING_SERVICES = `${LANDING}/#services`;
export const LANDING_LABEL = `${LANDING}/kicklox-label`;
export const LANDING_PLATFORM = `${LANDING}/kicklox-platform`;
export const LANDING_CLIENT = `${LANDING}/client`;

/* SOCIAL */
export const WELCOME = `https://www.welcometothejungle.co/companies/kicklox/jobs`;
export const FACEBOOK = 'https://www.facebook.com/kicklox';
export const TWITTER = 'https://twitter.com/kicklox_';
export const LINKEDIN = 'https://fr.linkedin.com/company/makake';
export const YOUTUBE =
  'https://www.youtube.com/channel/UCCqzvGrVpyIAjDnuXcIk86Q';
