export { default as Certification } from './CertificationModal';
export { default as Cv } from './CvModal';
export { default as CvDelete } from './CvDeleteModal';
export { default as Equipment } from './EquipmentModal';
export { default as Experience } from './ExperienceModal';
export { default as Categories } from './CategoriesModal';
export { default as Sector } from './SectorModal';
export { default as Expertise } from './ExpertiseModal';
export {
  default as IndividualCertificationSkills,
} from './IndividualCertificationSkillsModal';
export { default as KeyWordsModal } from './KeyWordsModal';
export { default as Degree } from './DegreeModal';
export { default as Language } from './LanguageModal';
export { default as Picture } from './PictureModal';
export { default as Project } from './ProjectModal';
export {
  default as PlatformRecommendation,
} from './PlatformRecommendationModal';
export { default as Skills } from './SkillsModal';
export { default as Summary } from './SummaryModal';
export { default as Modalities } from './ModalitiesModal';
export { default as CollaboratorNumber } from './CollaboratorNumberModal';
export { default as Collaborators } from './CollaboratorsModal';
export { default as ViewCollaborators } from './ViewCollaboratorsModal';
export { default as InterviewsConduct } from './InterviewsConductModal';
export { default as JoinUs } from './JoinUsModal';
export { default as CompanyValues } from './CompanyValuesModal';
export { default as Tools } from './ToolsModal';
export { default as Rewards } from './RewardsModal';
